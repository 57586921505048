<!--
 * @Author       : Hugo
 * @Date         : 2020-07-27 13:51:43
 * @LastEditors  : Hugo
 * @LastEditTime : 2022-04-15 14:10:59
 * @Description  : 
 * @FilePath     : /miaohang/src/components/Group/Aside/Template.vue
--> 
<template>
  <div class="group_aside_template">
    <div class="wrapper">
      <sidebar-search-group
        class="sidebar_search_group"
        ref="sidebar_search_group"
        v-if="0"
      ></sidebar-search-group>
      <div class="menu_group" v-for="(item,index) in menu" :key="index">
        <div class="menu_item"
          @click="goToMenu(item)"
        >
          <div class="menu_unit_group" :class="{on: item.name == menu_index && !item.children}" @click="menuHandler(item)">
            <div class="menu_unit cp" >
              <div class="name_group">
                <img :src="require(`@/assets/images/fun_template/${item.name}.png`)" alt="" class="icon">
                <div class="name un_sel">{{item.desc}}</div>
              </div>
              <div class="arrow" :class="{on: menu_index == item.name}" v-if="item.children" ></div>
            </div>
          </div>
          <div
            class="sub_menu_group"
            :class="{on: (item.name == menu_index || item.children) && !item.close}"
            v-show="(item.name == menu_index || item.children) && !item.close && item.children"
          >
            <template v-for="(child_item, child_index) in item.children">
              <div
              class="sub_menu_item cp un_sel"
              :class="{on: sub_menu_index == child_item.name && menu_index == item.name}"
              :key="`child${child_index}`"
              @click="sub_menu_index = child_item.name; goToSubMenu(item,child_item)"
              v-if="(child_item.name == 'sell' && $tools.getCookie('user').sign) || (child_item.name !='sell')">{{child_item.desc}}</div>
            </template>
            
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import store from '@/store'
import tools from '@/api/tools'
import SidebarSearchGroup from '@/components/sidebar/searchGroup.vue';
export default {
  components:{
    SidebarSearchGroup,
    // 非st；非科创；非北交所；流通市值<150亿；2个交易日前上涨；1个交易日前下跌；2个交易日前竞价额<1个交易日前竞价额;1个交易日前竞价额<0个交易日前竞价额;今日低开；
  },
  data(){
    return {
      menu: [
        {name: 'shopping_mail', desc: '模版商城'},
        {name:'new', desc: '新建任务'},
        {
          name:'my', 
          desc: '我的任务',
          children: [
            {name:'created', desc:'我创建的',},
            {name:'receive', desc:'我接收的',},
            {name:'purchase', desc:'我购买的',},
            {name:'sell', desc:'我销售的',},
            {name:'custom', desc:'我收藏的',},
          ]
        },
        {name:'draft', desc: '草稿箱'},
        {name:'recycle_bin', desc: '回收站'},
        // {name:'collect', desc: '收藏管理'},
        // {name:'market', desc: '培训市场'},
      ],
      menu_index: '',
      sub_menu_index: '',
    }
  },
  methods:{
    /**
     * 点击箭头
     */
    // arrowHandler(item){
    //   if(this.menu_index == item.name){
    //     this.menu_index = '';
    //     this.sub_menu_index = '';
    //   }else{
    //     this.menu_index = item.name;
    //   }
    // },
    /**点击一级菜单 */
    menuHandler(item){
      item.close = !item.close;
      if(this.menu_index == item.name && item.children){
        // 已经选中 并且有子菜单;
        this.menu_index = '';
        // this.sub_menu_index = '';
       
      }else{
        if(item.name == 'new'){
          // 新建模板
          // if(this.$dev_sign){
            this.$api.moduleTask.resetCallConfig();
            this.$nextTick(function(){
              this.$api.moduleTask.openTask({config:{create: true, select_target: true}});
            })
          // }else{
          //   this.$store.dispatch('openTaskBox', {is_new_task: true});
          // }
        }else {
          this.menu_index = item.name;
        }
      }
      // // console.log(this.menu_index)
    },
    /**
     * 跳转到子菜单
     */
    goToSubMenu(item, child_item){
      store.commit('set_collect_nav', undefined)
      tools.removeCookie('collect_nav')
      // 如果已在自定义分类 点击自定义分类不跳转
      if(item.name == 'my' && child_item.name == 'custom'){
        const {name} = this.$route;
        const {type} = this.$route.params;
        if(name == 'fun_template_my' && type=='custom' || name == 'fun_template_my_custom'){
          // console.log('不跳转');
          // 清空导航数据
         
          return;
        }
      }
      this.$router.push({name:`fun_template_${item.name}`, params: {type: child_item.name}});
    },
    goToMenu(item){
      if(item.name != 'my' && item.name !== 'new'){
        this.$router.push({name: `fun_template_${item.name}`});
        this.$store.commit('set_spm_nav', [{type: 'page', page_type:'featured'}]); // 重置导航商城
      }
    },
  },
  watch:{
    $route: function(val){
      let route = val;
      if(route.params.type && route.name == 'fun_template_my'){
        this.menu_index = 'my';
        this.sub_menu_index = route.params.type;
      }
      if(route.name.includes('shopping_mail')){
        this.menu_index = 'shopping_mail';
        this.sub_menu_index = '';
      }
    },
  },
  mounted(){
    let route = this.$route;
    if(route.params.type && route.name == 'fun_template_my'){
      this.menu_index = 'my';
      this.sub_menu_index = route.params.type;
    }
    if(route.name.includes('shopping_mail')){
      this.menu_index = 'shopping_mail';
      this.sub_menu_index = '';
    }
  }
}
</script>

<style lang="scss" scoped>
.group_aside_template{
  position:relative;
  z-index: 3;
  // width: 250px;
  width: 100%;
  height: 100%;
  overflow-y: auto;
 @include hidescrollbar;
  position: relative;
  .wrapper{
    overflow-x: visible;
    // width: 250px;
    width: 100%;
    .sidebar_search_group{
      position: sticky;
      top:0;
      z-index: 3;
    }
    .menu_group{
      width: 100%;
      &:last-child{
        .menu_item .menu_unit_group .menu_unit{
          // border-bottom: none;
        }
      }
      .menu_item{
        width: 100%;
        .menu_unit_group{
          @include bbox;
          @include transition;
          width: 100%;
          padding: {
            left: 17px;
          }
          &.on{
            background-color:#eee;
          }
          // 一级菜单
          .menu_unit{
            @include bbox;
            @include flexsb;
            width: 100%;
            padding: {
              top: 24px;
              bottom: 24px;
            }
            border-bottom: 2px solid $border_color;
          
            .name_group{
              @include flex;
              padding: {
                left: 10px;
              }
              .icon{
                width: 28px;
                height: 32px;
                display: block;
              }
              .name{
                padding-left: 15px;
                font-size:14px;
                
                font-weight:400;
                color:#333;
              }
            }
            .arrow{
              width:24px;
              height: 24px;
              margin-right: 17px;
              background: {
                size: 12px 8px;
                repeat: no-repeat;
                image: url(~@/assets/images/fun_template/menu_up.png);
                position: center;
              }
              &.on{
                background-image: url(~@/assets/images/fun_template/menu_down.png);
              }
            }
          }
        }
        
        // 二级
        .sub_menu_group{
          @include bbox;
          @include transition;
          width: 100%;
          padding: 5px 0;
          background:rgba(248,248,248,1);
          transform-origin: top;
          transform: scaleY(0);
          height: 0;
          &.on{
            transform: scaleY(1);
            height: auto;
          }
          .sub_menu_item{
            @include bbox;
            @include transition;
            padding-left: 67px;
            width: 100%;
            line-height:32px;
            font-size:14px;
            
            font-weight:400;
            color:rgba(85,85,85,1);
            &:hover{
              background-color: #eee;
            }
            &.on{
              background-color: #eee;
            }
          }
        }
      }
    }
   
  }
  
}
</style>